* {
  margin: 0;
}
body a {
  text-decoration: none;
  background-color: "text.main";
}

body {
  height: 100vh;
  font-family: "Arial";
  color: white;
}

.container {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100vh;
  display: grid;
  place-content: center;
}

.content {
  display: flex;
  gap: 5em;
  width: 100%;
  padding-top: 3em;
  position: relative;
}

.content:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid white;
  transform: scaleX(1);
}

h1 {
  font-size: 4rem;
  width: 50vw;
  line-height: 97%;
  text-align: right;
}

section {
  height: 100vh;
  color: white;
}

@keyframes move-background {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(1000px, 0px, 0px);
  }
}
@-webkit-keyframes move-background {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(1000px, 0px, 0px);
  }
}

@-moz-keyframes move-background {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(1000px, 0px, 0px);
  }
}

@-webkit-keyframes move-background {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(1000px, 0px, 0px);
  }
}

.background-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.stars {
  background: black
    url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/stars.png) repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 0;
}

.twinkling {
  width: 10000px;
  height: 100%;
  background: transparent
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/twinkling.png")
    repeat;
  background-size: 1000px 1000px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;

  -moz-animation: move-background 70s linear infinite;
  -ms-animation: move-background 70s linear infinite;
  -o-animation: move-background 70s linear infinite;
  -webkit-animation: move-background 70s linear infinite;
  animation: move-background 70s linear infinite;
}

.clouds {
  width: 10000px;
  height: 100%;
  background: transparent
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/clouds_repeat.png")
    repeat;
  background-size: 1000px 1000px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;

  -moz-animation: move-background 150s linear infinite;
  -ms-animation: move-background 150s linear infinite;
  -o-animation: move-background 150s linear infinite;
  -webkit-animation: move-background 150s linear infinite;
  animation: move-background 150s linear infinite;
}

@media screen and (max-width: 1280px) {
  .chart-container {
    padding-right: 0;
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.chart-container {
  padding-right: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.moon {
  width: 40%;
  position: absolute;
  z-index: 100000;
}
.chart-box {
  width: 50%;
  margin: 0 auto;
}
@media screen and (max-width: 600px) {
  .moon {
    width: 70%;
  }
  .chart-box {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}
::-webkit-scrollbar {
  display: none;
}
.sideBar::-webkit-scrollbar {
  display: none;
}

#SvgjsTspan1356 {
  color: gray !important;
}

.apexcharts-legend-text {
  color: lightgray !important;
}
.form-control {
  width: 200% !important;
  border-radius: 14px;
  height: 50px !important;
  padding: 10px;
  background-color: transparent;
  color: gray;
  border: 2px solid lightgray;
  min-width: auto;
  font-size: 17px;
}
@media (max-width: 1500px) {
  .form-control {
  width: 150% !important;
  }
}
@media (max-width: 1300px) {
  .form-control {
  width: 150% !important;

  }
}
@media (max-width: 1200px) {
  .form-control {
  width: 100% !important;
  

  }
}
@media (max-width: 600px) {
  .form-control {
  width: 100% !important;

  }
}
